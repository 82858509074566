import React, { useCallback, useState } from 'react';
import Image from 'next/image';
import classNames from 'classnames';
import { Anchor } from 'antd';
import { SectionTabType } from '@/shared/types/homepage';
import { size } from 'lodash';
import './index.less';

interface IProps {
  handleClickTab?: (tab: SectionTabType) => void;
  tabs: SectionTabType[];
}

const SectionTabs = ({ tabs, handleClickTab }: IProps) => {
  const lastTabIndex = size(tabs) - 1;
  const [activeTab, setActiveTab] = useState<string>(tabs[0].anchor);

  const handleTabChange = useCallback(
    (anchor: string) => {
      const activeTab = tabs.find((x) => x.anchor === anchor);
      if (activeTab) {
        setActiveTab(activeTab.anchor);
        handleClickTab?.(activeTab);
      } else {
        setActiveTab(tabs[0].anchor);
        handleClickTab?.(tabs[0]);
      }
    },
    [handleClickTab, tabs],
  );

  return (
    <Anchor
      offsetTop={80}
      targetOffset={180}
      className={classNames('section-tabs')}
      direction="horizontal"
      onChange={handleTabChange}
      onClick={(e, link) => {
        e.preventDefault();
        handleTabChange(link.href);
      }}
      items={tabs.map((tab, i) => ({
        key: tab.key,
        href: tab.anchor,
        title: (
          <div key={tab.key} className={classNames('tab')}>
            <div
              className="tab-content"
              style={{ backgroundColor: activeTab === tab.anchor ? tab.activeBgColor : 'transparent' }}
            >
              <span className="tab-title" style={{ color: activeTab === tab.anchor ? tab.activeTextColor : '#a1a1a1' }}>
                {tab.text}
              </span>
            </div>
            {i < lastTabIndex && (
              <Image
                className="chevron"
                src="/images/homepage-v2/chevron-right.svg"
                alt={tab.text}
                width={16}
                height={16}
              />
            )}
          </div>
        ),
      }))}
    />
  );
};

export default SectionTabs;
