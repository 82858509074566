import React, { useCallback } from 'react';
import './index.less';
import { ArrowRight } from '@/components/Common/Icons';
import ResponsiveContent from '../ResponsiveContent';
import Link from 'next/link';

const HomeRetailBanner = () => {
  return (
    <Link href="/platform/retail-connect" className="deal-banner">
      <span className="text-container">
        <span className="black-friday-pill" key="deal-banner-text-container-pietra-2">
          <span className="text">New</span>
        </span>
        <ResponsiveContent
          set={[
            [
              <span key={1} className="text">
                Get your brand into retail without fees
              </span>,
              720,
            ],
            [
              <span key={2} className="text">
                Pietra gets brands into retail without fees
              </span>,
            ],
          ]}
        />
      </span>
      <span className="cta">
        <ArrowRight className="right-icon" />
      </span>
    </Link>
  );
};

export default HomeRetailBanner;
